module.exports = [{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-catch-links@5.14.0_gatsby@5.14.1/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-source-wordpress@7.14.0_gatsby-plugin-image@3.14.0_gatsby-plugin-sharp@5.14.0_gatsby-t_zez3jsu7yl3fv6nb7wwu6gpyyy/node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://safesitesecuritysolutions.temp927.kinsta.cloud/graphql","production":{"hardCacheMediaFiles":true,"allow404Images":true,"allow401Images":false},"develop":{"hardCacheMediaFiles":true,"hardCacheData":true,"nodeUpdateInterval":5000},"html":{"useGatsbyImage":false,"createStaticFiles":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"schema":{"perPage":20,"requestConcurrency":1,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000,"previewRequestConcurrency":5},"type":{"MediaItem":{"createFileNodes":true,"excludeFieldNames":["contentNodes","seo","ancestors","author","template","lastEditedBy","authorDatabaseId","authorId","contentTypeName","dateGmt","desiredSlug","enclosure","isContentNode","isTermNode","modified","modifiedGmt","parentDatabaseId","parentId","parent","children"],"localFile":{"excludeByMimeTypes":["video/mp4","image/jpeg","image/png","image/gif","image/svg+xml"],"maxFileSizeBytes":50000000,"requestConcurrency":100},"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-styled-components@6.14.0_babel-plugin-styled-components@2.1.4_gatsby@5.14.1_rea_t5dlzhgf4qd26v4vmfpzsuohaa/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.14.0_gatsby@5.14.1_react-dom@18.3.1_react@18.3.1/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TT7Q4XN","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../../gatsby-theme-safesite/gatsby-browser.jsx'),
      options: {"plugins":[],"brand":"SafeSite Security Solutions","wordpressSourceUrl":"https://safesitesecuritysolutions.temp927.kinsta.cloud","googleTagManagerId":"GTM-TT7Q4XN","siteUrl":"https://www.safesitesecuritysolutions.co.uk","optimizeId":"OPT-TCZZJ4Q","trustpilotBusinessUnitId":"59e0886b0000ff0005ad8ae5","trustpilotUrl":"https://uk.trustpilot.com/review/safesitesecuritysolutions.co.uk","calltracksClass":"calltracks_mainwebsolutions","productReviewFormEndpoint":"https://getform.io/f/1d62c14e-ff3c-4309-b14b-e63490a1a915","careerApplicationFormEndpoint":"https://getform.io/f/bb07ab5c-51b7-4668-8e92-7a0a5f8950ac","quoteRequestFormEndpoint":"https://getform.io/f/b6c9248b-6bff-4e55-966d-44413dba8f24","contactFormEndpoint":"https://getform.io/f/950b91f7-4ebd-41a6-818f-e842722e3b1d","feedbackFormEndpoint":"https://getform.io/f/89f53cc5-fd87-46a4-adc0-f63ee3bbd1d1","quoteBasketFormEndpoint":"https://getform.io/f/797db8af-6635-4c5e-b17c-a4fc9d1036b2","largeNavFont":false},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1_graphql@16.10.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SafeSite Security Solutions","short_name":"SafeSite Security Solutions","lang":"en","start_url":"/","background_color":"#f7f0eb","theme_color":"#C61E1E","display":"standalone","icon":"src/images/icon.png","legacy":false,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1c95a702ea8263ea001637594b2c3a93"},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_react-dom@18.3.1_react@18.3.1_typescript@5.8.2/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
